import React from 'react'
import Header from './Header'
import Left_menu from './Left_menu'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import queryString from 'query-string';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
// import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import download from 'downloadjs'
import Moment from 'react-moment';
import { useNetworkState } from 'react-use';
import Footer from '../Footer';

const Rate_calculator = () => {
    let navigate=useNavigate()
    const location = useLocation()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    // console.log("location==>122", location)
     let  parsed = queryString.parse(location.search);
     // console.log("parsed",parsed);
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})

    const[parceltype,setparceltypeState] = React.useState({parcel:true})
    const[documenttype,setdocumenttypeState] = React.useState({document:false})
    const[state,setState] = React.useState({  parcel_type:"Parcel" , from_postal_code:"" ,to_postal_code:"" , mode:"Domestic" , weight:"" , length:"" , width:"" , height:"" })
    // console.log("state === " , state)
    const[active , setactive]= React.useState(0)
    const [statepackage, setStatepackage] = React.useState([]);
    const[loadingstate,setLoadingstate] = React.useState({loading:false})
    const[errorstate,seterrorstate] = React.useState({message:""})
  const[paymentmode,setpaymentmode]= React.useState({mode:1})
  const [codvalue,setcodvalue] = React.useState({cod_amount:0 , err:{}})
  const[volume, setVolume] = React.useState()
  
  const[volstate , setVolstate] = React.useState(null)
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    

    const handleChange=(e)=>{
        // // console.log("namess",e.target.name)
        // // console.log("value",e.target.value)
       
        let names = e.target.name
        let value= e.target.value
       
        if(e.target.type=="text"){
        setState({...state,[e.target.name]:value})
        }
        else if(e.target.type=="radio"){
            if(e.target.checked==true){
              
                 if(e.target.name=="document"){
                  setparceltypeState({...parceltype,parcel:false})
                  setdocumenttypeState({...documenttype,document:true})
                  setState({...state,parcel_type:"Document"})
                }
                else{
                  setparceltypeState({...parceltype,parcel:true})
                  setdocumenttypeState({...documenttype,document:false})
                  setState({...state,parcel_type:"Parcel"})
                }
            }
        }
        else if(e.target.type=="number"){
          setState({...state,[e.target.name]:value})
        }
        else if(e.target.type=="date"){
            setState({...state,[e.target.name]:value})
           
        }
    }

   
    const changebar =()=>{
        setactive(1)
      setState({...state , mode:"International"})
    }
    const changebar1 =()=>{
        setactive(0)
      setState({...state , mode:"Domestic"})
    }

    // const submit =()=>{
    //     navigate({pathname:`/customer/rate_package`,
    //    search: `sender_postal_code=${state.from_postal_code}&receiver_postal_code=${state.to_postal_code}&mode=${state.mode}&shipment_type=${state.parcel_type}&weight=${state.weight}&height=${state.height}&width=${state.width}&length=${state.length}&`
    //  })
     
    // }
    const get_list = async (sortValue) => {
        setLoadingstate({...loadingstate,loading:true})
        if(state.from_postal_code!="" && state.to_postal_code!="" && state.mode!="" && state.parcel_type!="" && state.weight!="" && state.height!="" && state.width!="" && state.length!=""){
       if(paymentmode.mode==1 || paymentmode.mode==2 && codvalue.cod_amount!="" ){

        let sort = "default";
        if(sortValue) {
          sort = sortValue
        }
        let dataToSend = {customer_id:userData.customer_id,
          from_postal_code:state.from_postal_code ,
           to_postal_code:state.to_postal_code, 
           weight:volume && state.weight!=""?parseFloat(volume)>parseFloat(state.weight)?volume:state.weight:0.00,
           length:state.length,
           height:state.height,
           width:state.width,
           sort_by:sort ,
            parcel_type:state.parcel_type ,
             mode:state.mode,
             payment_mode:paymentmode.mode==2?"cod":"prepaid",
             order_amount:codvalue.cod_amount
      };
      
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/shipment/shipment_rate_time';
       
       // // console.log("headers => ", config.headers);
       
        
        axios.post(url, dataToSend, { headers: config.headers })
            .then((responseJson) => {
              // console.log("responseJson => ", responseJson.data);
              setLoadingstate({...loadingstate,loading:false})
              if(responseJson.data.status=="success"){
                setStatepackage(responseJson.data.output)
                if(responseJson.data.output.length==0){
                  seterrorstate({...errorstate,message:"No services available for the selected route and package combination."})
                }
              }
    
              else{
              
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  Toast.fire({
                    background:"#0d6efd",
                    type: 'Unsuccess',
                    title: responseJson.data.message,
                    color:"white"
                  });
              }
               
            })
            .catch((error) => {
                //Hide Loader
                setLoadingstate({...loadingstate,loading:false})
                // console.log(error);
            });
          }
          else{
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
            })
            
            Toast.fire({
              background:"rgb(231, 91, 75)",
              type: 'unsuccess',
              title: "Please enter cod value",
              color:"white"
            });
            setLoadingstate({...loadingstate,loading:false})

           }
        }
        else{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                background:"#0d6efd",
                type: 'Unsuccess',
                title: "Please Fill All the Fields",
                color:"white"
              });
              setLoadingstate({...loadingstate,loading:false})

        }
    };
    
    // const sort_by = (e) =>{
    // // console.log(e.target.value);
    // get_list(e.target.value)
    // }
    React.useEffect(() => {
        // if(apiFlag){
        //   apiFlag = false;
        // get_list()  
        vol_status_function()
        dimension_detail()    
        // }
    }, [])
    const dimension_detail = () =>{
        let full_api = config.apiUrl + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          // console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }

      const reset =()=>{
        setState({...state , parcel_type:"Parcel" , from_postal_code:"" ,to_postal_code:"" , mode:active==0?"Domestic":"International" , weight:"" , length:"" , width:"" , height:""})
        setdocumenttypeState({...documenttype,document:false})
        setparceltypeState({...parceltype,parcel:true})
        setcodvalue({...codvalue,cod_amount:0 , err:{}})
      }
      const payment_mode =(e)=>{
        if(e.target.name=="prepaid"){
          setpaymentmode({mode:1})
        }
        else{
          setpaymentmode({mode:2})
        }
      }
      const codvalueChange=(e)=>{
      
        setcodvalue({...codvalue,cod_amount:e.target.value , err:{}})
      }
      const vol_status_function = () =>{
        let full_api = config.apiUrl + `/user/customer_volumetric_status`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setVolstate(res.data.volumetric_status);
          // console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }
      
      React.useEffect(() => {
        if ( state.height !== "" && state.width !== "" && state.length !== "") {
            let divisor = volstate === 1 ? 5000 : 1;
            const calculatedVolume = ( state.height * state.width * state.length) / divisor;
            setVolume(calculatedVolume.toFixed(2));
        } else {
            setVolume(0);
        }
    }, [ state.height, state.width, state.length, volstate]);
  return (
    <div>
<Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>
        

        <section class="mb-3 mt-3 tools">
          <div class="">
            <h3 class="py-3">Shipping Rate Calculator</h3>
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a class={active==0?"nav-link active":"nav-link"} style={{cursor:"pointer"}} onClick={()=>changebar1()}>Domestic</a>
                </li>
                <li class="nav-item">
                    <a class={active==1?"nav-link active":"nav-link"} style={{cursor:"pointer"}}  onClick={()=>changebar()}>International</a>
                </li>
            </ul>
            <div class="row py-3">
                <div class="col-12 col-md">
                    <div class="card border-0 order_box shadow-sm">
                        <div class="card-body">
                            <section class="pb-5">
                                <div class="row">
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="" class="form-label">Shipment Type:</label>
                                        <div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"  onChange={(e)=>handleChange(e)} name="document" id="inlxineRadio1"  checked={documenttype.document==true && parceltype.parcel==false}/>
                                              <label class="form-check-label" for="inlineRadio1">Document</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="parcel" onChange={(e)=>handleChange(e)} id="inlixneRadio2" value={state.parcel_type} checked={parceltype.parcel==true && documenttype.document==false }/>
                                              <label class="form-check-label" for="inlineRadio2">Parcel</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="" class="form-label">Pickup Pincode:</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter pickup pincode" name="from_postal_code" value={state.from_postal_code} onChange={(e)=>handleChange(e)}/>
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="" class="form-label">Delivery Pincode:</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter delivery pincode" name="to_postal_code" value={state.to_postal_code} onChange={(e)=>handleChange(e)} />
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="" class="form-label">Actual Weight:</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Enter Weight"  name="weight"  onChange={(e)=>handleChange(e)} value={state.weight}/>
                                            <span class="input-group-text">KG</span>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="" class="form-label">Dimensions:</label>
                                        <div class="row">
                                            <div class="col-12 col-md-4 mb-3">
                                                <div class="input-group">
                                                    <input type="text" class="form-control" placeholder="H" onChange={(e)=>handleChange(e)} name="height" value={state.height}/>
                                                    <span class="input-group-text">CM</span>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 mb-3">
                                                <div class="input-group">
                                                    <input type="text" class="form-control" placeholder="W" onChange={(e)=>handleChange(e)} name="width"  value={state.width}/>
                                                    <span class="input-group-text">CM</span>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 mb-3">
                                                <div class="input-group">
                                                    <input type="text" class="form-control" placeholder="L" onChange={(e)=>handleChange(e)} name="length" value={state.length}/>
                                                    <span class="input-group-text">CM</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="" class="form-label">Mode:</label>
                                        <div>
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input" type="radio" name="cod" id="inlineRadio2" value="option2" onChange={(e) => payment_mode(e)} checked={paymentmode.mode==2} />
                                              <label class="form-check-label" for="inlineRadio1">Cash On Delivery</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input" type="radio" name="prepaid" id="inlineRadio1" value="option1" onChange={(e) => payment_mode(e)} checked={paymentmode.mode==1}  />
                                              <label class="form-check-label" for="inlineRadio2">Prepaid</label>
                                            </div>
                                        </div>
                                    </div>
                                    {paymentmode.mode==2?
                        <div class="row">
                          <div class="col-12 col-md-5 mb-3">
                            <input type="text" class="form-control" id="" placeholder="Enter COD amount" name="cod_amount" 
                            onChange={(e)=>codvalueChange(e)} value={codvalue.cod_amount} />
                          </div>
                      </div>:""}
                                    {/* <div class="col-12 col-md-6 mb-3">
                                        <label for="" class="form-label">Shipment Value (₹):</label>
                                        <div class="input-group">
                                            <span class="input-group-text">₹</span>
                                            <input type="text" class="form-control" placeholder="Enter the shipment values" value="0.00" />
                                        </div>
                                    </div> */}
                                    {/* <div class="col-12 col-md-6 mb-3">
                                        <label for="" class="form-label">Shipping Dangerous Goods?:</label>
                                        <div>
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                              <label class="form-check-label" for="inlineRadio1">Yes</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                              <label class="form-check-label" for="inlineRadio2">No</label>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <h6>Volumetric Weight <span class="ms-5">{volume} Kg</span></h6>
                      
                        <h6 class="mb-3 mt-3">Applicable Weight  <span className='ms-5'>{volume && state.weight !== "" ? 
      (parseFloat(volume) > parseFloat(state.weight) ? volume : state.weight) + " Kg" :
      "0.00 Kg"
    }</span> </h6>
                                <div>
                                    <a onClick={()=>get_list()} class="btn btn-primary">Calculate</a>
                                    <a onClick={()=>reset()} class="btn btn-outline-primary">Reset</a>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    
                </div>
            </div>
          </div>
          <div class=" pt-3">
          <div class="row">
              <div class="col-12 col-md-12">
                  <div class="row">
                      <div class="col-12 col-md">
                          {/* <h4>Select Courier Partner</h4> */}
                      </div>
                      <div class="col-12 col-md-4 text-end">
                         <small> Pickup From
                          <strong>{state.from_postal_code}</strong> | 
                          Deliver To
                          <strong>{state.to_postal_code}</strong></small>
                      </div>
                  </div>
                  {/* <div class="row pt-3 mb-3">
                      <div class="col-12 col-md">
                          <ul class="nav nav-pills">
                              <li class="nav-item">
                              <a class="nav-link active" href="#">All</a>
                              </li>
                              <li class="nav-item">
                              <a class="nav-link" href="#">Air</a>
                              </li>
                          </ul>
                      </div>
                      <div class="col-12 col-md-4">
                          <select class="form-select form-select-sm" onChange={(e)=>sort_by(e)}>
                          <option>Sort By: Recommended</option>
                          <option value="low">Sort By: Cheapest</option>
                          </select>
                      </div>
                  </div> */}
                  <section class="package_box">
                  {loadingstate.loading?
            <div style={{width:"100%", display: "flex",
    justifyContent: "center"}}>
  
      
          <ReactLoading type={"spin"} color={"#0d6efd"} height={300} width={89} />
          </div>
          :""}
           <h3>
              {errorstate.message}</h3>
                      <div class="card mb-3">
                      {(statepackage.length != 0) ?
            statepackage.map((item,index)=>
                        <div class="card-body">
                          <div class="row">
                              <div class="col-12 col-md">
                                  <div class="d-flex">
                                      <div class="p-2">
                                          <figure>
                                              <img src={`https://cdn.shreebanshidharlogistics.com/images/${item.carrierImage}`} class="img-fluid" />
                                          </figure>
                                      </div>
                                      <div class="ps-2 ">
                                          <h4>{item.service_provider}</h4>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12 col-md-4 text-end">
                                  <h6>{item.product_type_name?item.product_type_name:""} 
                                  {/* | Min-weight: 0.5 kg */}
                                  </h6>
                              </div>
                          </div>
                         
                          <div class="row">
                              <div class="col-12 col-md-2">
                                  <div class="card rating_card">
                                    <div class="card-body">
                                      <h5>Rating 3.5</h5>
                                      <div class="text-warning">
                                          <i class="material-icons-outlined">star</i>
                                          <i class="material-icons-outlined">star</i>
                                          <i class="material-icons-outlined">star</i>
                                          <i class="material-icons-outlined">star_half</i>
                                          <i class="material-icons-outlined">star_border</i>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                              <div class="col-12 col-md pt-3">
                                  <h5>Expected Pickup</h5>
                                  <small>-----</small>
                              </div>
                              <div class="col-12 col-md pt-3">
                                  <h5>Estimated Delivery</h5>
                                  <small>----</small>
                              </div>
                              <div class="col-12 col-md pt-3">
                                  <h5>Weight</h5>
                                  <small>-----</small>
                              </div>
                              <div class="col-12 col-md pt-3">
                                  <h3>{dimensionState.companyDetail.map((subscriber)=>( 
                                    <>{subscriber.set_currency}</>
                                   ))}{parseFloat(Number(item.total_charges)+Number(item.commission_charge)).toFixed(2)}</h3>
                              </div>
                              {/* {(otherState.submit_button) ? */}
                            
                                 
                              {/* :  <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />} */}
                          </div>
                          {/* <div className="mt-3">
                          <hr/>
                          </div> */}
                        </div>
                        
                        ):""}
                      </div>
                   
                      
                    
                  </section>
              </div>
          </div>
      </div>
        </section>
        
          
        
</div>


    {/* <?php include 'footer.php';?> */}
    <Footer/>
    </div>
  )
}

export default Rate_calculator
