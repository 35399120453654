import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Billing_nav from './Billing_nav'
import {  useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'
import axios from "axios";
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar, ModalFooter } from "react-bootstrap"
import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../../config"
import TablePagination from '@mui/material/TablePagination';
import { useNetworkState } from 'react-use';
import Footer from '../Footer'

const Billing_wallet = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    let navigate = useNavigate()
    function todayDateString() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    const[balancestate, setbalanceState ]= React.useState({add_balance:[], total_balance:[] , total_credit:0 , total_debit:0})
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const [otherStates,setOtherState] = React.useState({dstatus:"",activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [modal , setmodal]=React.useState({show:false})
    const [date , setDate]=React.useState({from_date:"", to_date:""})
    const [error, setError] = React.useState(null);
    const [filters, setfilters] = React.useState('all');


    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
   React.useEffect(()=>{
    get_wallet_balance(0,true)
    dimension_detail()
   },[otherStates.rowsPerPage])

    const get_wallet_balance=(index=0,onLoad)=>{
     let dataToSend = {customer_id : userData.customer_id ,  limit:otherStates.rowsPerPage,indexValue:index , filters:filters};
     if(date.from_date!=""){
      dataToSend.from_date = date.from_date
     }
     if(date.to_date!=''){
      dataToSend.to_date = date.to_date
     }

     
     
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/user/get_wallet_balance';
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            if(res.data.status==true){
              if( onLoad) {
                setOtherState({...otherStates,total_count:res.data.count, page:index})         
  
    }
        setbalanceState({add_balance:res.data.added_wallet_transactions, total_balance:res.data.find_customer_wallet , total_credit:res.data.credit_value , total_debit:res.data.debit_value})
     }
    else{
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'Unsuccess',
            title: "Something Went Wrong !",
            color:"white"
          });
    } })
        
          .catch((error) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'Unsuccess',
                title: "Something Went Wrong !",
                color:"white"
              });
              // console.log(error);
          });    
    
    }
    const handlePageChange = (event,newPage) =>{
      setOtherState({...otherStates,page:newPage})
      // console.log("newPage ", newPage);
      // getCountryList(newPage);
      get_wallet_balance(newPage,true)
  }
  const handleChangeRowsPerPage = (event , newPage) => {
      // console.log("event ", event.target.value);
      setOtherState({ ...otherStates, rowsPerPage: event.target.value })
       
  }
   
    const dimension_detail = () =>{
        let full_api = config.apiUrl + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          // console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }

      const openModal=()=>{
        setmodal({show:true})
      }

      const handleDateChange = (e)=>{
        setDate({...date , [e.target.name]:e.target.value})
      }

    

          

//       const handleDownload = async () => {
//         try {
        
//           const response = await axios.post(
//             config.apiUrl + '/user/download_ledger_report',
//             {
//               from_date: date.from_date,
//               to_date: date.to_date,
//               customer_name:userData.full_name,
//               customer_id:userData.customer_id
            
//             },
//             {
            
//               headers: config.headers
//             }
//           );
//           const walletTransactions = response.data.output;
//           console.log(walletTransactions)

    
//           const data = transformDataForExcel(date.from_date, date.to_date, userData.full_name, userData.customer_id, walletTransactions);

//     // Create a new Excel workbook
//     const ws = XLSX.utils.aoa_to_sheet(data);

// // Create workbook
// const wb = XLSX.utils.book_new();
// XLSX.utils.book_append_sheet(wb, ws, 'Ledger Report');


//     XLSX.writeFile(wb, 'ledger_report.xlsx');
          
//         } catch (error) {
//           // setError('An error occurred while downloading the ledger report.');
//           console.log("error === " , error)
//         }
        
//       };

      function formatDate(timestamp) {
        const date = new Date(timestamp * 1000); // Multiply by 1000 to convert to milliseconds
        const day = ("0" + date.getDate()).slice(-2); // Add leading zero if needed
        const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }

      const transformDataForExcel = (fromDate, toDate, customerName, customerId, transactions) => {
        // Prepare data array
        const data = [];
    
        // Add headers
        data.push(['From Date',fromDate, 'To Date' , toDate]);
        // data.push([
        //     { value: 'from_date', style: { bold: true } },
        //     { value: 'to_date', style: { bold: true } },
        // ]);
        // data.push([fromDate, toDate]);
        data.push(['Customer Name:',customerName]);
    
        // data.push([ customerName,]);
    
        data.push([
            'Account Number', customerId
           
        ]);
    
        data.push([]); // Empty row
    
        // Add table headers
        data.push(['Date', 'AWB Number', 'Description', 'Debit', 'Credit','Balance']);
        let balance = 0;
        // Add transaction data
        transactions.forEach(transaction => {
          let dateField = '';

  if (transaction.date && transaction.date !== '') {
    dateField = transaction.date;
  } else if (transaction.created && transaction.created !== '') {
    dateField = formatDate(transaction.created);
  }
  if (transaction.payment_type === 2) {
    balance -= transaction.amount; // Debit
  } else if (transaction.payment_type === 1) {
    balance += transaction.amount; // Credit
  }

            data.push([
              dateField, // Assuming 'created' is the date field
                transaction.carrier_tracking_number, // Assuming 'payment_method' is the description field
                transaction.payment_method,
                transaction.payment_type === 2 ? transaction.amount : '', // Debit if payment_type is 1
                transaction.payment_type === 1 ? transaction.amount : '', // Credit if payment_type is 2
                balance
            ]);
        });
    
        data.push([]); // Empty row
    
        // Add total debit and total credit
        const totalCredit = transactions.reduce((total, transaction) => {
            return total + (transaction.payment_type === 1 ? transaction.amount : 0);
        }, 0);
    
        const totalDebit = transactions.reduce((total, transaction) => {
            return total + (transaction.payment_type === 2 ? transaction.amount : 0);
        }, 0);
        data.push(['', '','', 'Total Debit', 'Total Credit']);
    
        data.push(['', '','', totalDebit, totalCredit]);
    
        return data;
    };
    const handlefilters =(e)=>{
      setfilters(e.target.value)
    }

    const fetchReportData = async () => {
      const requestBody = {
        from_date: date.from_date,
        to_date: date.to_date,
        filters: filters,
      };
  
      try {
        const response = await axios.post(config.apiUrl +'/user/get_sheet_wallet_balance', requestBody, {
          headers:config.headers
        });
  
        return response.data;
      } catch (error) {
        console.error('Error fetching report data:', error);
        return null;
      }
    };

    const formatDataForSheet = (data) => {
      return data.map(item => ({
        'Date/Time': new Date(item.created * 1000).toLocaleString(),
        'Particulars': item.payment_method,
        'Transaction': item.transaction_id,
        'Credit': item.payment_type === 1 ? item.amount : '',
        'Debit': item.payment_type === 2 ? item.amount : ''
      }));
    };
  
    const handleDownload = async () => {
      
      const data = await fetchReportData();
      if (!data) return;
  
      const workbook = XLSX.utils.book_new();
  
      if (filters === 'all' || filters === 'deduction') {
        const deductionData = data.added_wallet_transactions.filter(item => item.payment_type === 2);
        const formattedDeductionData = formatDataForSheet(deductionData);
        const deductionSheet = XLSX.utils.json_to_sheet(formattedDeductionData);
        XLSX.utils.book_append_sheet(workbook, deductionSheet, 'Deduction');
      }
  
      if (filters === 'all' || filters === 'refund') {
        const refundData = data.added_wallet_transactions.filter(item => item.payment_type === 1 && item.shipment_id);
        const formattedRefundData = formatDataForSheet(refundData);
        const refundSheet = XLSX.utils.json_to_sheet(formattedRefundData);
        XLSX.utils.book_append_sheet(workbook, refundSheet, 'Refund');
      }
  
      if (filters === 'all' || filters === 'recharge') {
        const rechargeData = data.added_wallet_transactions.filter(item =>
          item.payment_type === 1 &&
          ['RazorPay', 'Easebuzz', 'Cashfree', 'Phonepay'].includes(item.payment_method)
        );
        const formattedRechargeData = formatDataForSheet(rechargeData);
        const rechargeSheet = XLSX.utils.json_to_sheet(formattedRechargeData);
        XLSX.utils.book_append_sheet(workbook, rechargeSheet, 'Recharge');
      }
  
      if (filters === 'all' || filters=== 'weight') {
        const weightData = data.added_wallet_transactions.filter(item => item.payment_method.startsWith('Weight Discrepancies for'));
        const formattedWeightData = formatDataForSheet(weightData);
        const weightSheet = XLSX.utils.json_to_sheet(formattedWeightData);
        XLSX.utils.book_append_sheet(workbook, weightSheet, 'Weight Reconciliation');
      }
  
      XLSX.writeFile(workbook, 'wallet_report.xlsx');
    };
    
  return (
    <div>
  <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>
<Billing_nav value={9}/>
<Modal show={modal.show} onHide={()=>setmodal({...modal,show:false})} size="lg">
        
                         <div class="modal-content">
                             <div class="modal-header">
                             Download Ledger Report   
                             </div>
                             <div class="modal-body">
                               <div className='row'>
                                <div className='col-6'>
                                  <label>From Date</label>
                                  <input className='form-control' type="date" name="from_date" value={date.from_date} onChange={(e)=>handleDateChange(e)}></input>
                                </div>
                                <div className='col-6'>
                                <label>To Date</label>
                                  
                                <input className='form-control' type="date" name="to_date"  value={date.to_date} onChange={(e)=>handleDateChange(e)}></input>
                                </div>

                               </div>
                    
                                <hr/>
                                <button className='btn btn-primary' onClick={(e)=>handleDownload(e)}>Download Report</button>
                                
                    
                            </div>
                            <ModalFooter>
                           
                                <button type="button" class="btn btn-link text-white ms-auto bg-black text-decoration-none" onClick={()=>setmodal({...modal,show:false})}>Close</button>
                           
                            </ModalFooter>
                            
                        </div>
                    
                </Modal>
             
        <section class="mb-3">
          <div class="">
            <div class="card border-0">
              <div class="card-body">
                <div class="row states stboxbil py-3">
                    <div class="col-12 col-md-2 mb-3">
                        {/* <div class="stbox text-center py-4 border rounded  ">
                        {balancestate.total_balance.map((sub)=>( <h5>&#8377;{parseFloat(sub.wallet_balance).toFixed(2)}</h5>))}
                            <small>Total Balance</small>
                        </div> */}
                           <label>From Date</label>
                                  <input className='form-control' type="date" name="from_date" value={date.from_date} onChange={(e)=>handleDateChange(e)}></input>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        {/* <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;{parseFloat(balancestate.total_credit).toFixed(2)}</h5>
                            <small>Total Credit</small>
                        </div> */}
                        <label>To Date</label>
                                  
                                  <input className='form-control' type="date" name="to_date"  value={date.to_date} onChange={(e)=>handleDateChange(e)}></input>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        {/* <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377; -{parseFloat(balancestate.total_debit).toFixed(2)}</h5>
                            <small>Total Debit</small>
                        </div> */}
                         <label>Filters</label>
                        <select className='form-select' onChange={(e)=>handlefilters(e)}>
                          <option value="all">All</option>
                          <option value="deduction">Deduction</option>
                          <option value="recharge">Recharge</option>
                          <option value="refund">Refund</option>
                          <option value="weight">Weight Reconciliation</option>
                          </select>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                      <div className='row'>
                        <div className='col-md-3'>
                        <button className='btn btn-primary btn-sm mt-4' onClick={(e)=>get_wallet_balance(0,true)}>View Report</button>
                        </div>
                        <div className='col-md-4'>
                        <button className='btn btn-primary btn-sm mt-4' onClick={(e)=>handleDownload(e)}>Downlaod Report</button>
                        </div>
                      </div>
                       
                    </div>
                    {/* <div class="col-12 col-md-2 mb-3">
                    <button className='btn btn-primary btn-sm mt-4' onClick={(e)=>handleDownload(e)}>Downlaod Report</button>
                    </div> */}
                </div>
              </div>
            </div>     
          </div>
        </section>

        <section class="mb-3">
          <div class="">
           
            <div class=" table-responsive">
                <table class="table table-bordered table_box billing_table">
                    <thead>
                      <tr>
                        <th>Date/Time</th>
                        <th>Particluars</th>
                        <th>Transaction</th>
                        <th>Credit</th>
                        <th>Debit</th>

                       
                      </tr>
                    </thead>
                    <tbody>
                    {balancestate.add_balance.map((sub)=>(
                      <tr>
                        <td><Moment format="MMM DD YYYY">
        {new Date(sub.created*1000)}
</Moment>  <Moment format="hh:mm:ss a ">
        {new Date(sub.created*1000)}
</Moment></td>
<td>{sub.payment_type==1?`Credited into the wallet (${sub.payment_method})`:`Debited from the wallet (${sub.payment_method})`}</td>
                        <td><a href="">{sub.transaction_id}</a></td>
                        {/* <td>{sub.amount}</td> */}
                        <td className='text-success'>{sub.payment_type==1 ?<>₹ {sub.amount}</> :""}</td>
                        <td className='text-danger'> {sub.payment_type!=1 ?<>- ₹ {sub.amount}</> :""}</td>
                       
                      </tr>
                    ))}
                      
                   
                   
                    </tbody>
                </table>  
            </div>
          </div>
        </section>
        <TablePagination
                component="div"
                rowsPerPageOptions={[20,25,30,35,40,45,50]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        />
   </div>

    {/* <?php include 'footer.php';?> */}
    <Footer/>
    </div>
  )
}

export default Billing_wallet
