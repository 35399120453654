import React from 'react'
import Header from './Header'
import Footer from '../Footer'
import Left_menu from './Left_menu';

const Footer_settings = () => {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
  const redirectToShipmentsPage = (statuses) => {
    const statusParam = statuses.join('%2C'); // Join status array with comma and encode
    window.location.href = `/customer/shipments?status=${statusParam}`;
};
  const handleDeliveredShipmentsClick = () => {
    // Redirect to Shipments page with only status 5 included
    redirectToShipmentsPage([5]);
};
const handleBookedShipmentsClick = () => {
  // Redirect to Shipments page with all statuses included
  redirectToShipmentsPage([1, 2, 3, 4, 5,6]);
};
const handleNdrShipmentsClick = () => {
// Redirect to Shipments page with all statuses included
redirectToShipmentsPage([8]);
};
const handleRtoShipmentsClick = () => {
// Redirect to Shipments page with all statuses included
redirectToShipmentsPage([10]);
};
const handleScheduledShipmentsClick = () => {
// Redirect to Shipments page with all statuses included
redirectToShipmentsPage([9]);
};
  return (
    <div>
        <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className="home-section">
        <Header/>
      <div class="container width_custom_0">
        
        <div class="row mt-3">

                <div class="col-12 col-md-4" >
                        <h3>Orders</h3>
                    <ul class="list-group list-group-flush mb-4">
                        <li class="list-group-item">
                            <a href="/customer/orders" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Customer Orders</h6>
                                   
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="/customer/create_order_1?value=0" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Create New Order</h6>
                                    
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="/customer/order_import" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Import Bulk Orders</h6>
                                  
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-12 col-md-4 px-0c" >
                        <h3>Shipments</h3>
                    <ul class="list-group list-group-flush mb-4">
                        <li class="list-group-item">
                            <a href="/customer/shipments" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">All Shipments</h6>
                                  
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        
               
               
             
                        </li>
                        <li class="list-group-item">
                            <a onClick={handleBookedShipmentsClick} class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Booked Shipments</h6>
                                   
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a onClick={handleDeliveredShipmentsClick} class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Delivered Shipments</h6>
                                   
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a onClick={handleNdrShipmentsClick} class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">NDR Shipments</h6>
                                   
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a onClick={handleRtoShipmentsClick} class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">RTO Shipments</h6>
                                   
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>

                        <li class="list-group-item">
                            <a onClick={handleScheduledShipmentsClick} class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Scheduled Shipments</h6>
                                   
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                      
                    </ul>
                </div>
                
                <div class="col-12 col-md-4 px-0c" >
                        <h3>Warehouse</h3>
                    <ul class="list-group list-group-flush mb-4">
                        <li class="list-group-item">
                            <a href="/customer/warehouse" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Customer Warehouse</h6>
                                  
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                       
                    </ul>
                </div>

                <div class="col-12 col-md-4 px-0c" >
                        <h3>Billing</h3>
                       
              
                    <ul class="list-group list-group-flush mb-4">
                        <li class="list-group-item">
                            <a href="/customer/billing_shipping_charges" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Shipping Charges</h6>
                                   
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="/customer/billing_cod_remittance" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">COD Remittance</h6>
                                  
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="/customer/billing_wallet" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Wallet History</h6>
                                   
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                      
                    </ul>
                </div>

                <div class="col-12 col-md-4 px-0c" >
                        <h3>Tools</h3>
                    <ul class="list-group list-group-flush mb-4">
                        <li class="list-group-item">
                            <a href="/customer/rate_calculator" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Rate Calculator</h6>
                                   
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="/customer/aftership" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">After Ship</h6>
                                  
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>

                        <li class="list-group-item">
                            <a href="/customer/tickets" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Support</h6>
                                  
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                       
                    </ul>
                </div>

                <div class="col-12 col-md-4 px-0c">
                        <h3>Weight Discrepancy</h3>
                    <ul class="list-group list-group-flush mb-4">
                        <li class="list-group-item">
                            <a href="/customer/weight_discrepancies_list" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Weight Discrepancy</h6>
                                   
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-12 col-md-4 px-0c" >
                        <h3>Reports</h3>
                    <ul class="list-group list-group-flush mb-4">
                        <li class="list-group-item">
                            <a href="/customer/reports" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Shipment Report</h6>
                                 
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="/customer/invoices" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Invoices</h6>
                                   
               
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-12 col-md-4 px-0c" >
                        <h3>Other Settings</h3>
                    <ul class="list-group list-group-flush mb-4">
                    <li class="list-group-item">
                            <a href="/customer/edit_profile" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Profile</h6>
                                 </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="/customer/channel_list" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Channel Integration</h6>
                                 </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>

                        <li class="list-group-item">
                            <a href="/customer/label_settings" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Label Settings</h6>
                                 </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>

                        <li class="list-group-item">
                            <a href="/customer/customer_kyc" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Customer KYC</h6>
                                 </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>

                        <li class="list-group-item">
                            <a href="/customer/customer_bank" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Bank Information</h6>
                                 </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>


                

            </div>
            

</div>
</div>
<Footer value={4}/>
    </div>
  )
}

export default Footer_settings
